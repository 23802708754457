import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ButtonProps } from "@mui/material/Button/Button";
import { memo, useState, FC, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const Amendities: FC<{ buttonVariant?: ButtonProps["variant"] }> = memo(
  ({ buttonVariant = "outlined" }) => (
    <>
      <Typography variant="subtitle2" align="center">
        за нощувка
      </Typography>
      <Typography variant="h6" align="center" gutterBottom>
        безплатен паркинг
      </Typography>
      <Typography variant="h6" align="center" gutterBottom>
        wifi
      </Typography>
      <Typography variant="h6" align="center" gutterBottom>
        телевизор
      </Typography>
      <Typography variant="h6" align="center" gutterBottom>
        санитарен възел
      </Typography>
    </>
  )
);

const base = 45;

const b = {
  double: {
    4: 80,
    5: 90,
    6: 100,
    7: 100,
    8: 90,
    9: 80,
  },
  tripple: {
    4: 120,
    5: 135,
    6: 150,
    7: 150,
    8: 135,
    9: 120,
  },
  apartment: {
    4: 160,
    5: 180,
    6: 200,
    7: 200,
    8: 180,
    9: 160,
  },
};

const c = {
  double: {
    4: 96,
    5: 108,
    6: 120,
    7: 120,
    8: 108,
    9: 96,
  },
  tripple: {
    4: 144,
    5: 162,
    6: 180,
    7: 180,
    8: 162,
    9: 144,
  },
  apartment: {
    4: 192,
    5: 216,
    6: 240,
    7: 240,
    8: 216,
    9: 192,
  },
};

const PricingCard: FC<{ roomType: string; price: string }> = ({
  roomType,
  price,
}) => (
  <Grid item xs={12} md={4}>
    <Paper>
      <Box p={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {roomType}
          </Typography>
          <Box display="flex">
            <Typography variant="h3" color="primary">
              {price}
            </Typography>
            <Typography
              variant="h4"
              color="primary"
              style={{ alignSelf: "flex-end", marginLeft: 5 }}
            >
              лв.
            </Typography>
          </Box>
        </Box>
        <Amendities />
      </Box>
    </Paper>
  </Grid>
);

export const PricesSection: FC = () => {
  const [currentMonth, setMonth] = useState(7);
  const { ref, inView } = useInView({ triggerOnce: true });
  const handleSetMonthClick = (month) => {
    setMonth(month);
    window?.gtag?.("event", "view_prices_section_month", { value: month });
  };
  useEffect(() => {
    if (inView) {
      window?.gtag?.("event", "view_prices_section");
    }
  }, [inView]);
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ pt: 5, pb: 5 }}
        ref={ref}
      >
        <Box display="flex" justifyContent="center">
          <Typography
            color="primary"
            sx={({ palette }) => ({
              borderBottom: `3px solid ${palette.primary.main}`,
              lineHeight: 1,
              mb: 2,
            })}
            variant="h6"
            fontWeight="bold"
          >
            ЦЕНИ ЗА НОЩУВКИ
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" mb={2}>
          <Tabs
            value={currentMonth}
            indicatorColor="primary"
            onChange={(_, value) => handleSetMonthClick(value)}
            variant={"scrollable"}
          >
            <Tab label="МАЙ" value={4} sx={{ p: 1 }} />
            <Tab label="ЮНИ" value={5} sx={{ p: 1 }} />
            <Tab label="ЮЛИ/АВГУСТ" value={7} sx={{ p: 1 }} />
            <Tab label="СЕПТЕМВРИ" value={8} sx={{ p: 1 }} />
          </Tabs>
        </Box>
        <Grid container spacing={2}>
          <PricingCard
            roomType="Двойна стая"
            price={c["double"][currentMonth]}
          />
          <PricingCard
            roomType="Тройна стая"
            price={c["tripple"][currentMonth]}
          />
          <PricingCard
            roomType="Апартамент"
            price={c["apartment"][currentMonth]}
          />
        </Grid>
      </Box>
    </>
  );
};
